module.exports = {
  name: "mcb",
  client: "STANDING EVT",
  description: "Mobile app for MCB 2022",
  validLangs: ["fr"],
  version: {
    mainVersion: "2.0.1",
    buildVersion: {
      iOS: "40",
      android: "39",
      web: "40"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    iosBuildTarget: "11.0",
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 30,
    androidSigning: {
      keyAlias: "mobilespot",
      keyStorePassword: "jee1Uu0Hieloh7bi"
    },
    app: "app-react"
  },
  undeliveredFolders: ["source", "exports"],
  crypto: false,
  web: {}
};