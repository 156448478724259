var _defineProperty = require("/Users/mohamedbenkhedher/Documents/react/mspot-mcb-2022/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty.js").default;

var _require;

var defaultConf = require('./config-default');

var BO_URL = "mcb2022-data.ms-dev.mobile-spot.com";
var BO_TAIGA_URL = 'https://mcbbybs2021.site.calypso-event.net';
var NODE_BACKEND_DOMAIN = 'mcb-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = '';
var LOCAL_NODE_BACKEND_DOMAIN = 'http://localhost:3082';
module.exports = require('deepmerge')(defaultConf, (_require = {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: "/home/legacy/standingevt/mcb2022/mcb2022-data.ms-dev.mobile-spot.com",
  // no trailing '/'
  BO_SERVER_ALIAS: "ms-dev.mobilespot.prodleni.local",
  BO_SSH_USER: "legacy",
  // 'www'
  BO_SSH_PORT: "22",
  //'43122'
  DEPLOY_PATH: '/home/legacy/standingevt/mcb2022/mcb2022.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-37',
  projectId: '363300849335',
  // FCM sender id
  appId: 'B9500-1676E',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  LOGIN: {
    forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile/mot-de-passe-oublie.htm")
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    MODE: 'peering',
    // (peering or login)
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  }
}, _defineProperty(_require, "LOGIN", {
  forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile/mot-de-passe-oublie.htm")
}), _defineProperty(_require, "REAL_TIME", {
  URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
}), _defineProperty(_require, "SSO", {
  SSO_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/sso-request")
}), _defineProperty(_require, "SOCIAL", {
  TWITTER: {
    POST_PER_PAGE: 10
  },
  YOUTUBE: {
    POST_PER_PAGE: 5
  },
  FACEBOOK: {
    POST_PER_PAGE: 5
  },
  FACEBOOK2: {
    POST_PER_PAGE: 5
  },
  INSTAGRAM: {
    POST_PER_PAGE: 5
  },
  URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social") // `${LOCAL_NODE_BACKEND_DOMAIN}/social`,

}), _defineProperty(_require, "SECURED_PDF", {
  SECURED_PDF_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/secured-pdf-viewer") // `${LOCAL_NODE_BACKEND_DOMAIN}/secured-pdf-viewer`,

}), _require));